import React, { useState, useRef, MouseEvent } from "react";
import useLocalStorageState from "use-local-storage-state";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { Canvas, extend, useThree, useFrame } from "@react-three/fiber";
import { useSpring, a } from "@react-spring/three";
import BurgerMenu from "./components/BurgerMenu";
import DragMouseToMoveMessage from "./components/DragMouseToMoveMessage";
import ColorPicker from "./components/ColorPicker";
import "./App.css"

extend({ OrbitControls });

const Controls = ({autoRotate}) => {
  const orbitRef = useRef();
  const { camera, gl } = useThree();
  
  useFrame((state) => orbitRef.current.update());

  return(
    <orbitControls
      args={ [camera, gl.domElement] }
      ref={ orbitRef }
      autoRotate={autoRotate}
      // enableDamping={true}
      // dampingFactor={2.0}
    />
  );
};

const Box = () => {
  // const [hovered, setHovered] = useState(false);
  // const [selected, setSelected] = useState(false);
  const boxRef = useRef();

  //useFrame((state) => boxRef.current.update());

  return(
    <a.mesh
      // onPointerOver={() => setHovered(true)}
      // onPointerOut={() => setHovered(false)}
      // onClick={() => setSelected(!selected)}
      // scale={selected ? [1,1.5,1.5] : [1,1,1]}
      ref={boxRef}
    >
      <boxBufferGeometry attach="geometry" args={[1,1,1]} />
      {/* <meshPhysicalMaterial attach="material" color={hovered ? "green" : "orange"} /> */}
      <a.meshStandardMaterial attach="material" color={"orange"} />
    </a.mesh>
  );
};

const torusInitialColor = "#6f94d1";
const torusKnotColorStorageKey = "torusKnotColor";
const TorusKnot = ({color=torusInitialColor}) => {
  const torusKnotRef = useRef();
  return(
    <mesh
      ref={torusKnotRef}
    >
      <torusKnotBufferGeometry attach="geometry" args={[10, 2.5,500,100,3,5]} />
      <meshStandardMaterial attach="material" color={color} />
    </mesh>

  );
}

const autoRotateStorageKey = "autoRotate";
// eslint-disable-next-line import/no-anonymous-default-export
export default() => {
  const [autoRotate, setAutoRotate] = useLocalStorageState(autoRotateStorageKey, {defaultValue: true});
  const [torusKnotColor, setTorusKnotColor] = useLocalStorageState(torusKnotColorStorageKey, {defaultValue: torusInitialColor});
  const [messageIsVisible, setMessageIsVisible] = useState(true);
  const [colorPickerIsVisible, setColorPickerIsVisible] = useState(false);

  function toggleAutoRotate() {
    setAutoRotate(prevValue => !prevValue);
  };

  function handleMouseMove(e) {
    if (e.buttons !== 0 /*any button down*/)
      setMessageIsVisible((isVisible) => false);
  };

  function handleTouchMove(e) {
    setMessageIsVisible(isVisible => false);
  };

  function handleColorChange(newColor) {
    setTorusKnotColor(color => newColor);
  };

  return(
    <>
      <BurgerMenu pageWrapId="page-wrap" outerContainerId="root"
        handleToggleAutoRotate = {toggleAutoRotate} 
        handleShowModelColorPicker = {() => setColorPickerIsVisible(isVisible => true)}
      />
      <div id="page-wrap">
        <DragMouseToMoveMessage isVisible={messageIsVisible} />
        <Canvas
          camera={{ position: [0, 30, 40], fov: 50 }}
          onMouseMove={handleMouseMove}
          onTouchMove={handleTouchMove}
        >
          <ambientLight intensity={0.3} />
          {/* <directionalLight position={[0, 5, 10]} intensity={0.2} /> */}
          <pointLight position={[10, 12, 5]} intensity={1.3} />
          <pointLight position={[-8, -10, -6]} intensity={0.4} />
          <Controls autoRotate={autoRotate} />
          {/* <Box /> */}
          <TorusKnot color={torusKnotColor}/>
        </Canvas>
        <ColorPicker
          headerText="Model Color"
          color={torusKnotColor}
          isVisible={colorPickerIsVisible}
          setIsVisible={setColorPickerIsVisible}
          handleColorChange={handleColorChange} />
      </div>
    </>
  );
}
