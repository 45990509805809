import React from "react";
import "./IconBase.css"

const MoveIcon = ({color="#65686B"}) => {
  return(
    <span className="icon-base">
      <svg xmlns="http://www.w3.org/2000/svg"
        stroke={color}
        fill={color}
        strokeWidth="25"
        viewBox="0 0 1024 1024"
      >
        <path d="M435.27522 811.87855v-223.65524H214.127326v135.39667L0 508.991185l212.12145-212.121449v138.405484h223.65524v-220.646425h-135.39667L515.008815 0l212.121449 212.12145h-138.405484v223.15377h220.646425V300.38002l213.625857 214.628795-212.12145 212.121449v-138.405484h-222.652302v220.646425h133.892263l-214.628795 214.628795-212.12145-212.12145h138.906954z" />
      </svg>
    </span>
    
  );
};
export default MoveIcon;