import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "./BurgerMenu.css";

const BurgerMenu = ({handleToggleAutoRotate, handleShowModelColorPicker}) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    function handleStateChange(state) {
        setMenuIsOpen(menuIsOpen => state.isOpen);
    }
    return (
        <Menu right isOpen={menuIsOpen} onStateChange={handleStateChange}>
            {/* <ul> */}
            <li href="/#" onClick={() => {handleToggleAutoRotate(); setMenuIsOpen(menuUsOpen => false)}}>Toggle autorotate</li>
            <li href="/#" onClick={() => {handleShowModelColorPicker(); setMenuIsOpen(menuUsOpen => false)}}>Change model color</li>
            {/* </ul> */}
        </Menu>
    );
};

export default BurgerMenu;