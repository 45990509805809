import React from "react";
import "./IconBase.css"

const CloseIcon = ({color="#65686B"}) => {
  return(
    <span className="icon-base">
      <svg xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 100 100' 
        fill='none' 
        stroke={color} 
        strokeWidth='25' 
        strokeLinecap='butt' 
        strokeLinejoin='bevel'>
          <line x1="10" y1="10" x2="90" y2="90" />
          <line x1="90" y1="10" x2="10" y2="90" />
      </svg>
    </span>
    
  );
};
export default CloseIcon;