import React from "react";
import "./icons/CloseIcon.js";
import "./CloseButton.css";
import CloseIcon from "./icons/CloseIcon.js";

const CloseButton = ({color="#65686B", handleOnClick}) => {
 
  return(
    <button className="close-button" 
      type="button" 
      title="Close"
      onClick={handleOnClick}
      onTouchStart={handleOnClick}
    >
      <CloseIcon color={color} />
    </button>
  );
}

export default CloseButton