import React from "react";
import { HexColorPicker } from "react-colorful";
import Draggable from "react-draggable";
import CloseButton from "./CloseButton";
import MoveIcon from "./icons/MoveIcon";

import "./ColorPicker.css";

const ColorPicker = ({headerText = "Drag Here", isVisible=false, setIsVisible, color, handleColorChange}) => {
  const draggableDivNodeRef = React.useRef(null);
  function handleOnChange(newColor) {
    handleColorChange(newColor);
  }
  function handleCloseButtonClick() {
    setIsVisible(value => false);
  }
  return(
    <Draggable
        nodeRef={draggableDivNodeRef}
        handle=".color-picker-draggable-header">
      <div
        ref={draggableDivNodeRef}
        className="color-picker" 
        style={{display: isVisible ? "block" : "none"}}
      >
        <div className="color-picker-draggable-header">
          <div>
            <span style={{display: "inline-block", float: "left", marginRight: "0.5em"}}>
              <MoveIcon />
            </span>
            <span>
              {headerText}
            </span>
            <span style={{display: "inline-block", float: "right"}}>
              <CloseButton handleOnClick={handleCloseButtonClick} />
            </span>
          </div>
        </div>
        <HexColorPicker 
          color={color} 
          onChange={handleOnChange}
        />
      </div>
    </Draggable>
   
  );
}

export default ColorPicker